<!-- 期初期末统计 -->

<template>
  <div class="semester">
    <div class="globle_border" v-loading="loading">
      <!-- 顶部搜索 -->
      <div class="search">
        <el-input v-model="productNameSearch" placeholder="搜索商品名称" suffix-icon="el-icon-search" clearable
          @change="onSearch"></el-input>
        <el-input v-model="productCodeSearch" placeholder="搜索商品条形码" suffix-icon="el-icon-search" clearable
          @change="onSearch"></el-input>
        <el-select v-model="typeValue" placeholder="选择统计类型年月日" @change="handleSelectType" clearable>
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
        <!-- 年 -->
        <el-date-picker v-if="typeValue == 3 || typeValue == ''" v-model="yearValue" type="year" key="year"
          :placeholder="datePickerPlaceholder" :readonly="!typeValue"
          @change="(value) => handleSelectData(value, 'year')" value-format="yyyy" clearable></el-date-picker>
        <!-- 月 -->
        <el-date-picker v-if="typeValue == 2" v-model="monthValue" type="monthrange" range-separator="至"
          start-placeholder="开始月份" end-placeholder="结束月份" value-format="yyyy-MM"
          @change="(value) => handleSelectData(value, 'month')">
        </el-date-picker>
        <!-- 日 -->
        <el-date-picker v-if="typeValue == 1" v-model="dataValue" type="daterange" key="daterange"
          popper-class="sift-data" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
          @change="(value) => handleSelectData(value, 'data')" clearable value-format="yyyy-MM-dd">
        </el-date-picker>
      </div>

      <!-- 表格 -->
      <div class="globle_table">
        <el-table :data="tableData" style="width: 100%" max-height="540">
          <el-table-column label="序号" width="80" show-overflow-tooltip>
            <template slot-scope="scope">
              {{ scope.$index + 1 + (currentPage - 1) * pageSize }}
            </template>
          </el-table-column>
          <!-- <el-table-column min-width="120" show-overflow-tooltip prop="productNum" label="商品编号"></el-table-column> -->
          <el-table-column min-width="120" show-overflow-tooltip prop="barCode" label="商品条形码"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="productName" label="商品名称"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="categoryName" label="商品类目"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="brand" label="品牌"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="factoryName" label="厂家/商超"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="specs" label="规格"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="statType" label="类型">
            <template slot-scope="scope">
              <span>{{ scope.row.statType == 1 ? '日期' : scope.row.statType == 2 ? '月度' : '年度' }}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="statPeriod" label="时间">
            <template slot-scope="scope">
              <span>{{ formatStatPeriod(scope.row.statPeriod) }}</span>
            </template>
          </el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="begin_count" label="期初库存"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="inCount" label="入库总数"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="out_count" label="出库总数"></el-table-column>
          <el-table-column min-width="120" show-overflow-tooltip prop="end_count" label="期末库存"></el-table-column>
        </el-table>
      </div>
    </div>

    <!-- 分页 -->
    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
      :current-page.sync="currentPage" :page-size="pageSize" :page-sizes="pageSizeArr"
      layout="prev, pager, next, jumper, sizes, total" :total="totalItemsCount">
    </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      productCodeSearch: "", // 搜索商品条形码
      productNameSearch: "", // 搜索商品名称
      typeValue: '', // 选择年月日类型
      yearValue: '', // 选择年
      monthValue: '', // 选择月
      dataValue: '', // 时间区间
      startTime: '', // 开始时间
      endTime: '', // 结束时间
      options: [{
        value: '3',
        label: '年度'
      }, {
        value: '2',
        label: '月度'
      }, {
        value: '1',
        label: '日期'
      },],
      // 表格数据
      tableData: [],
      // 分页
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页显示的行数
      pageSizeArr: [10, 20, 50], // 每页显示的行数(可选)
      totalItemsCount: 0, // 总记录数（需要从后端获取）
    };
  },
  computed: {
    datePickerPlaceholder() {
      if (this.typeValue == '') {
        return "请先选择统计类型再选择时间";
      } else if (this.typeValue == 3) {
        return "选择年";
      } else {
        // 其他情况下的默认placeholder
        return "选择日期";
      }
    }
  },
  created() {
    this.getBeginEndList();
  },
  methods: {
    // 获取列表
    getBeginEndList() {
      this.loading = true;
      let params = {
        statType: this.typeValue || null, // 统计类型
        productName: this.productNameSearch || null, // 搜索商品名称
        // productNum: this.productCodeSearch || null, // 搜索商品编号
        barCode: this.productCodeSearch || null, // 搜索商品条形码
        startTime: this.startTime || null, // 开始时间
        endTime: this.endTime || null, // 结束时间
        page: this.currentPage,
        pageSize: this.pageSize,
      };

      this.$axios.get(this.$api.getBeginEndList, { params })
        .then((res) => {
          if (res.data.code == 100) {
            this.tableData = res.data.result.list;
            this.totalItemsCount = res.data.result.totalCount;
          }
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    formatStatPeriod(statPeriod) {
      statPeriod = statPeriod.toString();
      if (statPeriod.length === 6) {
        // 如果长度是6，则假设是年份和月份，格式为YYYYMM
        const year = statPeriod.substring(0, 4);
        const month = statPeriod.substring(4, 6);
        return `${year}-${month}`;
      } else if (statPeriod.length === 8) {
        // 如果长度是8，则假设包含年份、月份和日期，格式为YYYYMMDD
        const year = statPeriod.substring(0, 4);
        const month = statPeriod.substring(4, 6);
        const day = statPeriod.substring(6, 8);
        return `${year}-${month}-${day}`;
      } else {
        // 如果长度不是6也不是8，返回原始值
        return statPeriod;
      }
    },
    // 选择类型
    handleSelectType(value) {
      this.clearData();
      this.typeValue = value;
      if (!this.typeValue) {
        this.yearValue = '';
        this.monthValue = '';
        this.dataValue = '';
        this.startTime = '';
        this.endTime = '';
      }
      this.getBeginEndList();
    },
    // 时间搜索
    handleSelectData(value, time) {
      this.clearData();

      if (time == 'year') {
        // 年
        this.yearValue = value;
        this.startTime = value?.replace(/-/g, "");
        this.endTime = value?.replace(/-/g, "");
      } else if (time == 'month') {
        // 月
        this.monthValue = value;
        if (this.monthValue && this.monthValue.length === 2) {
          this.startTime = value[0].replace(/-/g, "");
          this.endTime = value[1].replace(/-/g, "");
        } else {
          this.startTime = null;
          this.endTime = null;
        }
      } else if (time == 'data') {
        // 日
        this.dataValue = value;
        if (this.dataValue && this.dataValue.length === 2) {
          this.startTime = value[0].replace(/-/g, "");
          this.endTime = value[1].replace(/-/g, "");
        } else {
          this.startTime = null;
          this.endTime = null;
        }
      }
      this.getBeginEndList();
    },
    // 清空
    clearData() {
      this.tableData = [];
      this.currentPage = 1;
    },
    // 搜索
    onSearch() {
      this.clearData();
      this.getBeginEndList();
    },
    // 切换每页显示的条数
    handleSizeChange(e) {
      this.clearData();
      this.pageSize = e;
      this.getBeginEndList();
    },
    // 换页
    handleCurrentChange(e) {
      this.tableData = [];
      this.currentPage = e;
      this.getBeginEndList();
    },
  },
};
</script>

<style scoped lang="scss">
.semester {

  // 顶部搜索
  .search {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 44px;
    margin-bottom: 20px;

    .el-input {
      width: 250px;
      margin-left: 20px;
    }

    .el-select {
      margin-left: 20px;
    }

    /deep/ .el-date-editor {
      background-color: #f2f2f2;
      border: none;
      border-radius: 10px;
      margin-left: 20px;
    }

    /deep/.el-range-input {
      background-color: #f2f2f2;

    }
  }
}
</style>
